<template>
  <div class="vi-register-school-individual">
    <svg width="100%" height="450" class="web-bg-svg">
      <rect
        width="150%"
        height="450"
        style="fill:rgb(41, 84, 162);"
        transform="rotate(-13 0,750) skewX(8)"
      ></rect>
    </svg>
    <div class="container">
      <div class="row mt-4 mb-5">
      
        <div class="col-md-12">
          <div class="card web-login-card p-4 mt-2 mb-5" v-if="verifyCode == false">
            <div style="margin-bottom:0;line-height:18px;" class="dispflex">
              <div>You are registering as a
              <h1>School/Institution</h1>
              </div>
               <div class="cardIcn"  @click.prevent="backToHome">
              
              <svg width="20px" height="20px" viewBox="0 0 28 28" version="1.1">
                <defs>
                  <path d="M0 0L28 0L28 28L0 28L0 0Z" id="path_1" />
                  <clipPath id="mask_1">
                    <use xlink:href="#path_1" />
                  </clipPath>
                </defs>
                <g id="Home-icon">
                  <path d="M0 0L28 0L28 28L0 28L0 0Z" id="Background" fill="#FFFFFF" fill-opacity="0" fill-rule="evenodd" stroke="none" />
                  <g clip-path="url(#mask_1)">
                    <path d="M13.9998 0.588379C13.878 0.588379 13.7567 0.62701 13.6531 0.70541L0.773084 10.7581C0.532284 10.9485 0.487528 11.3003 0.677928 11.5467C0.868328 11.7875 1.22012 11.8322 1.46652 11.6418L2.2398 11.0381L2.2398 25.7599C2.2398 26.0679 2.4918 26.3199 2.7998 26.3199L10.6398 26.3199L10.6398 16.2399L17.3598 16.2399L17.3598 26.3199L25.1998 26.3199C25.5078 26.3199 25.7598 26.0679 25.7598 25.7599L25.7598 11.0381L26.5331 11.6418C26.6395 11.7202 26.7566 11.7599 26.8798 11.7599C27.0478 11.7599 27.2097 11.6867 27.3217 11.5467C27.5121 11.3003 27.4673 10.9485 27.2265 10.7581L14.3465 0.70541C14.2429 0.62701 14.1216 0.588379 13.9998 0.588379L13.9998 0.588379ZM19.5996 2.80005L19.5996 3.38849L22.9596 6.00911L22.9596 2.80005L19.5996 2.80005L19.5996 2.80005Z" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
                  </g>
                </g>
              </svg>
            </div>
            </div>
            <form
              data-vv-scope="schoolRegister"
              id="schoolRegisterid"
              accept-charset="utf-8"
              autocomplete="false"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-md-3">
                  <span class="web-has-float-label">
                    <input
                      class="form-control web-form-input"
                      id="schnameid"
                      v-validate="'required'"
                      name="schoolName"
                      autocomplete="off"
                      placeholder="School name"
                      v-model="schoolName"
                      maxlength="100"
                    />
                    <label
                      v-if="
                        errors.first('schoolRegister.schoolName') && showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >School Name is required</label
                    >

                    <label for="schnameid" v-else
                      >School Name<span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 mt-md-3">
                  <span class="web-has-float-label">
                    <input
                      @input="checkAffilliateId"
                      type="text"
                      name="affid"
                      v-validate="'required'"
                      v-model="affiliateID"
                      class="form-control web-form-input"
                      id="affid"
                      placeholder="Affiliate ID"
                      maxlength="50"
                      autocomplete="false"
                      style="cursor: text;"
                    />

                    <label
                      v-if="
                        (errors.first('schoolRegister.affid') && showError) ||
                          !isAffiliateIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                      >{{
                        errors.first("schoolRegister.affid")
                          ? "Affiliate Id is required"
                          : "Affiliate Id already registered. please use a different one."
                      }}</label
                    >

                    <label for="affid" v-else
                      >Affiliate ID <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      name="fname"
                      v-validate="'required'"
                      class="form-control web-form-input"
                      id="fnameid"
                      placeholder="First Name"
                      v-model="firstName"
                      maxlength="50"
                    />

                    <label
                      v-if="errors.first('schoolRegister.fname') && showError"
                      class="vi-error"
                      style="top: -1em"
                      >First Name is required</label
                    >

                    <label for="fnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      name="lname"
                      v-validate="'required'"
                      class="form-control web-form-input"
                      id="lnameid"
                      placeholder="Last Name"
                      v-model="lastName"
                      maxlength="50"
                    />

                    <label
                      v-if="errors.first('schoolRegister.lname') && showError"
                      class="vi-error"
                      style="top: -1em"
                      >Last Name is required</label
                    >

                    <label for="lnameid" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      @focusout="checkEmail"
                      type="email"
                      name="email"
                      v-validate="'required'"
                      class="form-control web-form-input"
                      id="emailid"
                      placeholder="Email Id"
                      v-model="emailID"
                      maxlength="50"
                    />

                    <label
                      v-if="
                        (errors.first('schoolRegister.email') && showError) ||
                          !isEmailIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                      >{{
                        errors.first("schoolRegister.email")
                          ? "Email Id is required "
                          : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}
                    </label>
                    

                    <label for="emailid" v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 ">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      disabled
                      class="form-control form-input"
                      id="countryid"
                      placeholder="Country"
                      v-model="country"
                    />

                    <label for="countryid"
                      >Country<span class="required">*</span></label
                    >
                    <!-- <datalist id="countries">
                      <option value="India">India</option>
                    </datalist> -->
                  </span>
                </div>

                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      autocomplete="chrome-off"
                      class="form-control form-input"
                      placeholder="Search Address"
                      country="IN"
                      types=""
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                    <label for="countryid"
                      >Address<span class="required">*</span></label
                    >
                    <!-- <datalist id="countries">
                      <option value="India">India</option>
                    </datalist> -->
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    
                    <input
                      type="text"
                      v-model="state"
                      autocomplete="off"
                      class="form-control form-input"
                      id="stateValidate"
                      :disabled="!isAddressSelected"
                      v-validate="'required|alpha_spaces|max:100'"
                      name="stateValidate"
                      placeholder="State"
                    />

                    <label
                      v-if="
                        errors.first('schoolRegister.stateValidate') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >State is required</label
                    >

                    <label for="stateid" v-else
                      >State<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="district"
                      class="form-control form-input"
                      id="stateid"
                      v-validate="'required|alpha_spaces|max:100'"
                      name="districtValidate"
                      placeholder="State"
                    />

                    <label
                      v-if="
                        errors.first('schoolRegister.districtValidate') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >District is required</label
                    >

                    <label for="stateid" v-else
                      >District<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      v-model="city"
                      autocomplete="off"
                      class="form-control form-input"
                      id="cityid"
                      v-validate="'required|alpha_spaces|max:100'"
                      name="cityValidate"
                      placeholder="City"
                    />

                    <label
                      v-if="
                        errors.first('schoolRegister.cityValidate') && showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >City/Town is required</label
                    >

                    <label for="stateid" v-else
                      >City / Town<span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      v-validate="'required|numeric|max:6|min:6'"
                      v-model="pincode"
                      autocomplete="off"
                      class="form-control form-input"
                      id="stateid"
                      name="pinCodeValidate"
                      placeholder="pinCode"
                    />

                    <label
                      v-if="
                        errors.first('schoolRegister.pinCodeValidate') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >Pin Code is required</label
                    >

                    <label for="stateid" v-else
                      >Pin Code<span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 pr-md-0">
                  <span class="web-has-float-label">
                    <input
                      v-validate="'required'"
                      type="text"
                      name="stname"
                      class="form-control web-form-input"
                      id="stnameid"
                      placeholder="Street Name"
                      v-model="streetName"
                      maxlength="50"
                    />

                    <label
                      v-if="errors.first('schoolRegister.stname') && showError"
                      class="vi-error"
                      style="top: -1em"
                      >Street Name is required</label
                    >
                    <label for="stnameid" v-else
                      >Street Name<span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==10) return false;"
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mobno"
                      class="form-control web-form-input"
                      id="mobnoid"
                      placeholder="Mobile"
                      v-model="mobileNo"
                    />

                    <label
                      v-if="
                        (errors.first('schoolRegister.mobno') && showError) ||
                          !isPhoneNumberAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                    >
                      {{
                        errors.first("schoolRegister.mobno")
                          ? "Please enter a valid mobile number(10 digits)"
                          : "Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}</label
                    >

                    <label for="mobnoid" v-else
                      >Mobile <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      name="password"
                      ref="password"
                      type="password"
                      class="form-control form-input"
                      placeholder="Password"
                      v-validate="'required'"
                      v-model="password"
                      autocomplete="off"
                      style="cursor: text;"
                      maxlength="50"
                    />

                    <label
                      v-if="
                        errors.first('schoolRegister.password') && showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >Password is required</label
                    >

                    <label for="pwdid" v-else
                      >Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="password"
                      name="Confirm Password"
                      placeholder="Password"
                      data-vv-as="password"
                      class="form-control form-input"
                      v-validate="'required|confirmed:password'"
                      v-model="confirmPassword"
                      id="confpwdid"
                      maxlength="50"
                    />

                    <label
                      v-if="
                        errors.first('schoolRegister.Confirm Password') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >Confirm Password mismatch and is required</label
                    >

                    <label for="confpwdid" v-else
                      >Confirm Password <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6"></div>

                <div class="form-group input-group col-md-12">
                  <div id="app" style="width: 100%;">
                    <GmapMap
                      :center="center"
                      :zoom="18"
                      map-style-id="roadmap"
                      :options="mapOptions"
                      style="width: 100%; height: 200px"
                      ref="mapRef"
                      @click="handleMapClick"
                    >
                      <GmapMarker
                        :position="marker.position"
                        :clickable="true"
                        :draggable="true"
                        @drag="handleMarkerDrag"
                        @click="panToMarker"
                      />
                    </GmapMap>
                    <!-- <button @click="geolocate">Detect Location</button> -->

                    <!-- <p>Selected Position: {{ marker.position }}</p> -->
                  </div>
                </div>
                
                <div
                  class="g-recaptcha"
                  id="recaptcha"
                  data-callback="checkResponse"
                  data-sitekey="6Lcs5UMeAAAAAApF9anTicewdEFZQbZesyw5C0VD"
                  style="width: 100%;"
                ></div>
               
                <div class="form-group col-md-12 web-btnalign pr-md-3">
                  <button
                    type="submit"
                    @click.prevent="submitForm"
                    class="web-custbutton mt-3 mr-2"
                  >
                    Register
                  </button>
                  <input
                    type="button"
                    class="web-cancelbutton mt-3"
                    value="Reset"
                    @click="clearForm"
                  />
                  
                </div>
              </div>
            </form>
            <vi-spinner
              v-if="viLoader"
              text="Loading..."
              textColor="vi-brand-color"
              class="flex-fill h-100 vi-fs12"
              style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
            />
          </div>
            <div class="row" v-else>
               <div class="col-md-6">
                 <img  src="/img/login_3.33db7843.svg" width="510" alt="Login" class="img-fluid heroimg mt-4">
               </div>
                <div class="col-md-6">
           <div class="card web-login-card p-4 mt-5 mb-5 " >
              <div class="forgot-password">
                <div class="dispflex" style="justify-content:start;">
                <div class="cardIcn mr-4" style="padding:0.66rem;width:40px;height:40px;"><i class="fa fa-arrow-left" aria-hidden="true" style="cursor:pointer;font-size:1.2rem" @click="backToRegister"></i></div><h1>Enter 4 digit code sent to your registered email id</h1>
                </div>
                <div class="form-row m-0 mt-3">
                    <div class="form-group input-group mt-3">
                      <span class="web-has-float-label">
                        <input
                          type="number"
                          onKeyPress="if(this.value.length==4) return false;"
                          name="verifyCode"
                           class="form-control web-form-input"
                          id="verifyCodeid"
                          v-model="userVerifyCode"
                          min="4"
                        />
                       
                        <label for="verifyCodeid" 
                          >4-digit code <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <button
                      type="submit"
                      class="web-custbutton mt-3 w-100"
                      @click="verifyCodeForforgotPassword"
                    >
                      Verify
                    </button>
                    <p class="pt-4 w-100" style="text-align:center;">Don't received code? <span style="color:#2954a2;font-weight:bold;cursor:pointer"   @click="resendCode">RESEND CODE</span></p>
                   
                    
                  </div>
              </div>
               <vi-spinner
              v-if="viCodeLoder"
              text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="position: absolute;width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              
            />
          </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "vi-register-school-individual",
  components: {
    ViSpinner,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      verifyCode : false,
      isAddressSelected: false,
      userVerifyCode: null,
      schoolRegisterData: {},
      schoolName: null,
      affiliateID: null,
      streetName: null,
      addressLine: null,
      state: null,
      district: null,
      city: "",
      longitude: "",
      latitude: "",
      pincode: null,
      country: "India",
      firstName: null,
      lastName: null,
      emailID: null,
      websiteURL: null,
      landlineNo: null,
      mobileNo: null,
      password: null,
      confirmPassword: null,
      serviceError: null,
      viLoader: null,
      viCodeLoder: null,
      serverSuccess: null,
      isEmailIdAvailable: true,
      isPhoneNumberAvailable: true,
      isAffiliateIdAvailable: true,
      showError: true,
      stateList: {
        AN: "Andaman and Nicobar Islands",
        AP: "Andhra Pradesh",
        AR: "Arunachal Pradesh",
        AS: "Assam",
        BR: "Bihar",
        CH: "Chandigarh",
        CT: "Chhattisgarh",
        DN: "Dadra and Nagar Haveli",
        DD: "Daman and Diu",
        DL: "Delhi",
        GA: "Goa",
        GJ: "Gujarat",
        HR: "Haryana",
        HP: "Himachal Pradesh",
        JK: "Jammu and Kashmir",
        JH: "Jharkhand",
        KA: "Karnataka",
        KL: "Kerala",
        LD: "Lakshadweep",
        MP: "Madhya Pradesh",
        MH: "Maharashtra",
        MN: "Manipur",
        ML: "Meghalaya",
        MZ: "Mizoram",
        NL: "Nagaland",
        OR: "Odisha",
        PY: "Puducherry",
        PB: "Punjab",
        RJ: "Rajasthan",
        SK: "Sikkim",
        TN: "Tamil Nadu",
        TG: "Telangana",
        TR: "Tripura",
        UP: "Uttar Pradesh",
        UT: "Uttarakhand",
        WB: "West Bengal",
      },
      districtList: [],
      pincodeList: [],
      address: "",
      marker: { position: { lat: 13.061931068989976, lng: 80.25831401348114 } },
      center: { lat: 13.061931068989976, lng: 80.25831401348114 },
      
      mapOptions: {
        disableDefaultUI: true,
      },
    };
  },

  mounted() {
    document.getElementById("map").innerHTML = document.getElementById("map").innerHTML;
    this.$refs.address.focus();
  },
  
  created() {
    this.initReCaptcha();
    
  },

  mounted() {
    this.panToMarker();
  },

  methods: {
    getAddressData(addressData, placeResultData, id) {

      this.isAddressSelected = true;

      this.streetName = addressData.route || "",
      this.state = addressData.administrative_area_level_1 || "",
      this.district = addressData.administrative_area_level_2 || "",
      this.pincode = addressData.postal_code || "";
      this.city = addressData.locality || "";
      
      this.marker.position.lng = addressData.longitude || "";
      this.marker.position.lat = addressData.latitude || "";
      
      this.panToMarker();

      if (this.state) {
        this.state = this.stateList[this.state];
      }

    },
    checkResponse() {
      google_recpach = grecaptcha.getResponse(this.live_google_recpachid);
    },
    async initReCaptcha() {
      setTimeout(() => {
        if (!window.grecaptcha || !window.grecaptcha.render) {
          initReCaptcha();
        } else {
          window.grecaptcha.render("recaptcha", {
           // sitekey: process.env.SITE_KEY,
            sitekey : '6Lcs5UMeAAAAAApF9anTicewdEFZQbZesyw5C0VD',
            theme: "light",
          });
        }
      }, 500);
    },
    async checkAffilliateId() {
      const response = await ViService.viGetOnly(
        `/common/checkApiName?cName=Account&affilliateId=${this.affiliateID}`
      );

      if (response.isSuccess) {
        this.isAffiliateIdAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    },
    backToHome() {
      this.$router.push("/");
    },
    async submitForm() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("schoolRegister").then((result) => {
        isFormValid = result;
      });

      if (
        isFormValid &&
        this.isEmailIdAvailable &&
        this.isPhoneNumberAvailable &&
        this.isAffiliateIdAvailable
      ) {
        const response = window.grecaptcha.getResponse();
        if (response) {
          let requestBody = {
            user: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.emailID,
              password: this.confirmPassword,
              phoneNo: Number(this.mobileNo),
              userName: this.emailID.toLowerCase()
            },
            accountDetails: {
              accountName: this.schoolName,
              address: this.streetName,
              affilliateId: this.affiliateID,
              state: this.state,
              pincode: this.pincode,
              district: this.district,
              country: this.country,
              email: this.emailID,
              supportMail: this.emailID,
              city: this.city || "",
              longitude: this.marker.position.lng.toString() || "",
              latitude: this.marker.position.lat.toString() || "",
              street: this.streetName || "",
            },
            type: "schoolAdmin",
          };
          this.landlineNo
            ? (requestBody.accountDetails["landlineNo"] = this.landlineNo)
            : null;

        this.schoolRegisterData = requestBody

        this.viLoader = true;

        ViService.viPost("/auth/mailVerification/code/sent", { email: this.emailID, schoolName : this.schoolName,affilliateId: this.affiliateID })
          .then((res) => {
            this.viLoader = null;
            
            if (res.isSuccess) {
              this.verifyCode = true
              this.$toasted.success(res.message);
            } else {
              this.$toasted.error(res.message);
            }
          })
          .catch((e) => {
            this.viLoader = null;
            let eData = errorLog.apiErrorLog(e);
            this.$toasted.error(eData);
          });

        } else {
          this.$toasted.error("Please Verify Captcha");
        }
      } else {
        this.$toasted.error(message);
      }
    },
    async resendCode() {
      this.viCodeLoder = true;
      ViService.viPost("/auth/resent/code", { email: this.emailID })
          .then((res) => {
            
            if (res.isSuccess) {
              this.viCodeLoder = null;
              this.$toasted.success(res.message);
            } else {
              this.viCodeLoder = null;
              this.$toasted.error(res.message);
            }
          })
          .catch((e) => {
            this.viCodeLoder = null;
            let eData = errorLog.apiErrorLog(e);
            this.$toasted.error(eData);
            console.log(eData);
          });

    },
    clearForm() {
      this.schoolName = null;
      this.affiliateID = null;
      this.streetName = null;
      this.addressLine = null;
      this.state = null;
      this.country = "India";
      this.firstName = null;
      this.lastName = null;
      this.emailID = null;
      this.websiteURL = null;
      this.landlineNo = null;
      this.mobileNo = null;
      this.password = null;
      this.confirmPassword = null;
      this.serviceError = null;
      this.streetName = "",
      this.state =  "",
      this.district = ""
      this.pincode = ""
      this.city = ""
      this.marker.position.lng = "";
      this.marker.position.lat = "";
      this.$refs.address.$refs.autocomplete.value = ''

    },
    backToRegister(){
     this.verifyCode = false
    },
     async verifyCodeForforgotPassword() {
        if(this.userVerifyCode){
        let requestBody = {
          registerData: this.schoolRegisterData,
          code: this.userVerifyCode,
        };
        
        this.viCodeLoder = true;
        ViService.viPost("/auth/verify/code", requestBody)
          .then((res) => {
            if (res.isSuccess) {
             
              this.$toasted.success("Registration Successful.Please wait....");
              this.showError = false
              setTimeout(() => {
                this.$router.push("/login");
              }, 3000);
              this.viCodeLoder = null;
              this.clearForm();
             
            }else {
              this.viCodeLoder = null;
             this.$toasted.error(res.message);
            } 
           
          })
          .catch((e) => {
          this.viCodeLoder = null;
            console.log(e);
          });
           }
    else{
      this.$toasted.error('Please enter 4 digit code..');
    }
    },
    async checkEmail() {
      this.emailID = this.emailID.toLowerCase();

      const response = await ViService.viGetOnly(
        `/common/checkApiName?cName=User&email=${this.emailID}`
      );
      if (response.isSuccess) {
        this.isEmailIdAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    },
    async checkMobile() {
      if (this.mobileNo) {
        const phoneNo = Number(this.mobileNo);

        const response = await ViService.viGetOnly(
          `/common/checkApiName?cName=User&phoneNo=${phoneNo}`
        );
        if (response.isSuccess) {
          this.isPhoneNumberAvailable = response.isApiNameAvailable;
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    getPincodeList(districtId) {
      this.pincodeList = [];
      if (districtId) {
        const codeList = this.districtList.find((x) => x._id == districtId);
        if (codeList) {
          if (codeList.pinCode.length == 1) {
            this.pincode = codeList.pinCode[0];
          }
          this.pincodeList = codeList.pinCode;
        }
      }
    },
    

    async getDistrictList(id) {
      if (id) {
        const response = await ViService.viGetOnly(
          `/common/fetchDistrictList?stateId=${id}`
        );
        if (response.isSuccess) {
          this.districtList = response.data;
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      } else {
        this.districtList = [];
      }
    },
    geolocate() {
      
      navigator.geolocation.getCurrentPosition((position) => {
        
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.panToMarker();
      });
    },

    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    panToMarker() {
      
      this.$refs.mapRef.panTo(this.marker.position);
    },

    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
  },
};
</script>

<style lang="scss" scoped></style>
